var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoiceDetailsView mx-auto" },
    [
      _vm.isMigrated
        ? _c("InvoiceMigrationStatementComponent", {
            attrs: {
              "show-download-button": _vm.hasZipFile,
              "data-test-id": "migration_statement",
            },
            on: {
              "on:download-migrated-invoices": _vm.onDownloadMigratedInvoices,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "InvoiceDetailsView__content emobg-background-color-white emobg-border-1 emobg-border-color-ground overflow-hidden p-4 pb-5",
          attrs: { "data-test-id": "invoice_details-view" },
        },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-wrap" },
            [
              _c("InvoiceActionsComponent", {
                staticClass: "w-100 mb-4",
                attrs: {
                  "is-change-customer-allowed": _vm.isOpenProformaOrDelivery,
                  "is-pdf-allowed": _vm.isPDFAllowed,
                  "is-create-credit-note-allowed":
                    _vm.isCreateCreditNoteAllowed,
                  "is-valid-status": _vm.isValidStatus,
                  "invoice-status": _vm.invoiceStatus,
                  "close-invoice-event": _vm.closeInvoiceEvent,
                },
                on: {
                  "on:close": _vm.onInvoiceClose,
                  "on:pdf": _vm.onShowPDF,
                  "on:change-customer": _vm.onChangeCustomer,
                  "on:create-credit-note": _vm.onRequestCreateDraftCreditNote,
                  "on:change": _vm.loadInvoice,
                },
              }),
              _c("InvoiceIssuerComponent", { staticClass: "col-6 my-2" }),
              _c(
                "div",
                { staticClass: "col-6 my-2 text-right" },
                [
                  _c("InvoiceIdentificationComponent", { staticClass: "mb-3" }),
                  _c("InvoiceCustomerComponent", {
                    attrs: {
                      "is-update-address-allowed": _vm.isUpdateAddressAllowed,
                    },
                    on: {
                      "on:update-customer-address": _vm.onUpdateCustomerAddress,
                      "on:change": _vm.loadInvoice,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 mb-4 align-items-center" },
                [
                  _vm.isValidStatus
                    ? _c(
                        "h5",
                        { staticClass: "height--size-m d-inline-block" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$labels.InvoiceDetailsTab.service_lines
                                  .title
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isOpenProformaOrDelivery
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            face: _vm.FACES.outline,
                            "data-test-id": "add-service-button",
                          },
                          on: { clickbutton: _vm.addService },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$labels.InvoiceDetailsTab.service_lines
                                  .actions.add
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.hasBookings || _vm.hasRefundableBookings
                    ? _c("InvoiceBookingComponent", {
                        attrs: {
                          "is-edit-allowed": _vm.isOpenProformaOrDelivery,
                        },
                        on: {
                          "on:change": _vm.loadInvoice,
                          "update:draft": _vm.onUpdateDraftCreditNote,
                        },
                      })
                    : _vm.isDraftCreditNote
                    ? _c("InvoiceRefundableServiceLineComponent", {
                        staticClass: "w-100 mb-6",
                        attrs: { "draft-services": _vm.callBookingService() },
                        on: { "update:draft": _vm.onUpdateDraftCreditNote },
                      })
                    : _c("InvoiceServiceLineComponent", {
                        staticClass: "w-100 mb-6",
                        attrs: {
                          "is-edit-allowed": _vm.isOpenProformaOrDelivery,
                          "invoice-id": _vm.invoiceId,
                        },
                        on: { "on:change": _vm.loadInvoice },
                      }),
                ],
                1
              ),
              _c("InvoiceTotalsComponent", {
                staticClass: "offset-6 col-6 mb-4",
              }),
              !_vm.isDraftCreditNote && (_vm.hasServiceLines || _vm.hasPayments)
                ? _c("InvoicePaymentsComponent", {
                    staticClass: "col-12",
                    on: { "on:change": _vm.loadInvoice },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.isOpenProformaOrDelivery
            ? _c("ServiceLineModal", {
                attrs: {
                  "is-modal-open": _vm.isServiceModalOpen,
                  title:
                    _vm.$labels.InvoiceDetailsTab.service_lines.modal.add_title,
                  "is-add-mode": "",
                },
                on: {
                  "on:close": _vm.onCloseServiceLineModal,
                  "on:change": _vm.loadInvoice,
                },
              })
            : _vm._e(),
          _vm.isOpen
            ? _c("InvoiceCloseModal", {
                attrs: { "is-modal-open": _vm.isInvoiceCloseModalOpen },
                on: {
                  "on:close": _vm.onCloseInvoiceCloseModal,
                  "on:change": _vm.loadInvoice,
                },
              })
            : _vm._e(),
          _vm.isOpenProformaOrDelivery
            ? _c("InvoiceChangeCustomerModal", {
                attrs: { "is-modal-open": _vm.isChangeCustomerModalOpen },
                on: {
                  "on:close": _vm.onCloseChangeCustomerModal,
                  "on:change": _vm.loadInvoice,
                },
              })
            : _vm._e(),
          _vm.isClosedInvoice
            ? _c("CreditNoteCreationConfirmationModal", {
                attrs: {
                  "is-modal-open": _vm.isDraftCreditNoteConfirmationModalOpen,
                },
                on: {
                  "on:close": _vm.onCloseCreditNoteCreationConfirmationModal,
                  "on:create-credit-note-draft": _vm.onCreateCreditNoteDraft,
                },
              })
            : _vm._e(),
          _vm.isDraftCreditNote
            ? _c("CreditNoteDraftCloseModal", {
                attrs: { "is-modal-open": _vm.isInvoiceDraftCloseModalOpen },
                on: {
                  "on:close": _vm.onCloseDraftCreditNoteModal,
                  "on:change": _vm.loadInvoice,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }