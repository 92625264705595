var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      attrs: {
        title: _vm.title,
        header: _vm.headerObject,
        "data-test-id": "service_line-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "ui-validate",
            {
              staticClass: "d-flex flex-wrap",
              on: { status: _vm.isFormAllValid },
            },
            [
              !_vm.isAddMode
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "d-block mx-2 mb-4 order-1",
                      attrs: {
                        color: _vm.COLORS.warning,
                        icon: _vm.ICONS.alertFull,
                        "data-test-id": "notification",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.service_lines.modal
                              .attention_text
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isBookingSelectorAllowed
                ? [
                    _vm.isBookingRequired
                      ? _c("MuiAlgoliaSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true\n          }",
                            },
                          ],
                          staticClass: "col-12 mb-4 order-2",
                          attrs: {
                            index: _vm.ALGOLIA_INDEXES.csBookings,
                            filters: _vm.customerFilter,
                            title: (booking) =>
                              _vm.displayBookingSelector(booking),
                            label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.booking}*`,
                            placeholder:
                              _vm.$labels.InvoiceDetailsTab.service_lines.modal
                                .booking_placeholder,
                            "path-value": "id",
                            name: "booking",
                            "data-test-id": "booking-select",
                          },
                          on: { selected: _vm.selectAlgolia },
                          model: {
                            value: _vm.bookingId,
                            callback: function ($$v) {
                              _vm.bookingId = $$v
                            },
                            expression: "bookingId",
                          },
                        })
                      : _c("MuiAlgoliaSelect", {
                          staticClass: "col-12 mb-4 order-2",
                          attrs: {
                            index: _vm.ALGOLIA_INDEXES.csBookings,
                            filters: _vm.customerFilter,
                            title: (booking) =>
                              _vm.displayBookingSelector(booking),
                            label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.booking}`,
                            placeholder:
                              _vm.$labels.InvoiceDetailsTab.service_lines.modal
                                .booking_placeholder,
                            "path-value": "id",
                            name: "booking",
                            "data-test-id": "booking-select",
                          },
                          on: { selected: _vm.selectAlgolia },
                          model: {
                            value: _vm.bookingId,
                            callback: function ($$v) {
                              _vm.bookingId = $$v
                            },
                            expression: "bookingId",
                          },
                        }),
                  ]
                : _vm._e(),
              _c("MuiAlgoliaSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.select",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true\n        }",
                    modifiers: { select: true },
                  },
                ],
                staticClass: "col-12 mb-4 order-3",
                attrs: {
                  index: _vm.ALGOLIA_INDEXES.services,
                  filters: `cs_operator_fk: ${_vm.currentOperatorId}`,
                  title: (service) =>
                    `${service.code} ${service.internal_name}`,
                  label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.service}*`,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.service_lines.modal
                      .service_placeholder,
                  "path-value": "id",
                  name: "service",
                  "data-test-id": "service-select",
                },
                on: { selected: _vm.selectAlgolia },
                model: {
                  value: _vm.serviceId,
                  callback: function ($$v) {
                    _vm.serviceId = $$v
                  },
                  expression: "serviceId",
                },
              }),
              _c(
                "div",
                { staticClass: "col-6 order-4" },
                [
                  _c("ui-text-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.input",
                        value: {
                          isRequired: true,
                          isPattern: {
                            pattern: _vm.POSITIVE_BUT_ZERO_PATTERN,
                            message: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.units_error}`,
                          },
                        },
                        expression:
                          "{\n            isRequired: true,\n            isPattern: {\n              pattern: POSITIVE_BUT_ZERO_PATTERN,\n              message: `${$labels.InvoiceDetailsTab.service_lines.modal.units_error}`,\n            },\n          }",
                        modifiers: { input: true },
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      value: _vm.units,
                      type: _vm.INPUT_TYPES.number,
                      label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.units}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .units_placeholder,
                      name: "units",
                      "data-test-id": "units-input",
                    },
                    on: { changevalue: _vm.onChangeUnits },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6 order-5" },
                [
                  _c("ui-text-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.input",
                        value: {
                          isRequired: true,
                          isPattern: {
                            pattern: _vm.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
                            message: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.price_error}`,
                          },
                        },
                        expression:
                          "{\n            isRequired: true,\n            isPattern: {\n              pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,\n              message: `${$labels.InvoiceDetailsTab.service_lines.modal.price_error}`,\n            },\n          }",
                        modifiers: { input: true },
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      value: _vm.amount,
                      type: _vm.INPUT_TYPES.number,
                      label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.price}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .price_placeholder,
                      name: "price",
                      "data-test-id": "price-input",
                    },
                    on: { changevalue: _vm.onChangeAmount },
                  }),
                ],
                1
              ),
              !_vm.isAddMode
                ? _c("ui-text-area", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.input",
                        value: {
                          isRequired: true,
                          isMinLength: {
                            message: "This field cannot be blank",
                            length: 1,
                          },
                          isMaxLength: {
                            message: `The number of characters allowed is ${_vm.maxTextAreaLength}`,
                            length: _vm.maxTextAreaLength,
                          },
                        },
                        expression:
                          "{\n          isRequired: true,\n          isMinLength: {\n            message: 'This field cannot be blank',\n            length: 1,\n          },\n          isMaxLength: {\n            message: `The number of characters allowed is ${maxTextAreaLength}`,\n            length: maxTextAreaLength,\n          },\n        }",
                        modifiers: { input: true },
                      },
                    ],
                    staticClass: "d-block w-100 mt-4 mx-2 order-6",
                    attrs: {
                      value: _vm.description,
                      label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.comments}* (${_vm.textAreaCharsCount} characters left)`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .comments_placeholder,
                      name: "description",
                      rows: "4",
                      "data-test-id": "description-input",
                      maxlength: _vm.maxTextAreaLength,
                    },
                    on: { changevalue: _vm.textAreaManager },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c("SaveButton", {
            attrs: {
              disabled: !_vm.isSaveAllowed,
              "data-test-id": "save-button",
            },
            on: { click: _vm.onSave },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }