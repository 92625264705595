<script>
import get from 'lodash/get';
import template from 'lodash/template';
import toNumber from 'lodash/toNumber';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  MuiAlgoliaSelect,
  MuiModal,
} from '@emobg/motion-ui/v1';

import { Validate } from '@emobg/vue-base';

import {
  cookie as cookieManager,
  DATE_FORMAT,
} from '@emobg/web-utils';
import moment from 'moment';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import { NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN } from '@/utils';
import { MAX_TEXT_AREA_LENGTH } from '@domains/Invoicing/const/TEXT_AREA_LENGTH';

const COMPENSATION_PAYMENT_METHOD_ID = 3;

export default {
  name: 'InvoicePaymentsAddModal',

  components: {
    CancelButton,
    MuiAlgoliaSelect,
    MuiModal,
  },

  directives: { Validate },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isOpen: false,
      isFormValid: false,
      amount: undefined,
      description: undefined,
      paymentMethodId: undefined,
      paymentMethodUuid: null,
      optionSelected: null,
      date: undefined,
      minAmount: 0.01,
      maxAmount: 0,
      maxTextAreaLength: MAX_TEXT_AREA_LENGTH,
      textAreaCharsCount: MAX_TEXT_AREA_LENGTH,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoicePayments.STATUS.LOADING'),
      isError: state => get(state, 'invoicePayments.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData']),

    isAddAllowed() {
      return this.isFormValid && !!this.date;
    },
  },

  watch: {
    isModalOpen(newValue) {
      this.isOpen = newValue;

      if (newValue) {
        this.clearData();
        this.maxAmount = get(this, 'invoiceData.pendingAmount.value') / 100;
        this.date = moment();
      }
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN = NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN;

    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };

    this.maxAmount = get(this, 'invoiceData.pendingAmount.value') / 100;

    this.textAreaCharsCount = this.maxTextAreaLength;

    this.csrevFeatureFlags = cookieManager.get('csrevFeatureFlags', true) ? JSON.parse(cookieManager.get('csrevFeatureFlags', true)) : null;

    this.isV6Version = this.csrevFeatureFlags ? this.csrevFeatureFlags['CSREV-3685-bo-add-payment'] : false;
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, [
      'postInvoicePayment',
      'postInvoicePaymentTransaction',
    ]),

    template,

    async onAddPayment() {
      const { description, paymentMethodUuid, paymentMethodId } = this;

      const payload = {
        amount: this.amount.toString(),
        date: this.date.format(DATE_FORMAT.date),
        description,
        paymentMethodId,
      };

      if (this.isV6Version) {
        const v6Payload = {
          amount: Number(this.amount) * 100,
          description,
          idempotencyKey: crypto.randomUUID(),
          paymentMethodUuid,
        };

        await this.postInvoicePaymentTransaction({
          invoiceUuid: this.invoiceData.uuid,
          payload: v6Payload,
        });
      } else {
        await this.postInvoicePayment({
          payload,
        });
      }

      this.$emit('on:close');

      if (!this.isError) {
        this.$notify({
          message: this.$labels.InvoiceDetailsTab.payments.modal.add.successfully,
          textAction: '',
        });
        this.$emit('on:change');
      }
    },

    isAmountValid(value) {
      const currentAmount = toNumber(value);

      return this.minAmount <= currentAmount && currentAmount <= this.maxAmount;
    },

    isFormAllValid(value) {
      this.isFormValid = value.detail.isValid && value.detail.areAllValidated;
    },

    clearData() {
      this.paymentMethodId = undefined;
      this.amount = undefined;
      this.date = undefined;
      this.description = undefined;
      this.textAreaCharsCount = this.maxTextAreaLength;
    },

    textAreaManager(text) {
      if (text.detail.length >= this.maxTextAreaLength) {
        this.textAreaCharsCount = 0;
        return;
      }

      this.description = text.detail;
      this.textAreaCharsCount = this.maxTextAreaLength - text.detail.length;
    },

    selectAlgolia(paymentSelected) {
      this.paymentMethodUuid = paymentSelected.uuid;
      this.paymentMethodId = paymentSelected.id;

      const minimumPaidAmount = this.invoiceData.paidAmount?.value / -100;

      const validator = this.isV6Version ? paymentSelected.internal : this.paymentMethodId === COMPENSATION_PAYMENT_METHOD_ID;

      this.minAmount = validator ? minimumPaidAmount : 0.01;

      this.validateAllInputs();
    },

    validateAllInputs() {
      const validatableInputs = document.querySelectorAll('.ui-to-validate');

      Array.from(validatableInputs)
        .filter(({ value }) => value)
        .forEach(validatedElement => {
          validatedElement.dispatchEvent(new CustomEvent('validate', { bubbles: true, cancelable: true }));
        });
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="$labels.InvoiceDetailsTab.payments.modal.add.title"
    :header="headerObject"
    class="InvoicePaymentsModal"
    data-test-id="payment-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <ui-validate
        class="d-flex flex-wrap mx-3"
        @status="isFormAllValid"
      >
        <div
          name="firstRow"
          class="w-100 d-flex"
        >
          <MuiAlgoliaSelect
            v-model="optionSelected"
            v-validate.select="{
              isRequired: true
            }"
            :index="ALGOLIA_INDEXES.paymentMethods"
            :title="method => method.name"
            :label="`${$labels.InvoiceDetailsTab.payments.modal.payment_method}*`"
            :placeholder="$labels.InvoiceDetailsTab.payments.modal.payment_method_plaheholder"
            :path-value="isV6Version ? 'uuid' : 'id'"
            name="paymentMethod"
            class="my-2 pl-0"
            :class="isV6Version ? 'w-100' : 'col-6'"
            data-test-id="payment_method-select"
            @selected="selectAlgolia"
          />

          <ui-datetimepicker
            v-if="!isV6Version"
            :date.prop="moment(date)"
            :size="SIZES.small"
            :label="`${$labels.InvoiceDetailsTab.payments.modal.date}*`"
            skiptime
            class="col-6 my-2 pr-0"
            data-test-id="date-input"
            @datechanged="({ detail }) => date = detail"
          />
        </div>

        <ui-text-input
          v-validate.input="{
            isRequired: true,
            isPattern: {
              pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
              message: $labels.InvoiceDetailsTab.payments.modal.amount_format_error,
            },
            isAmountOutOfRange: (value) => {
              const limits = { min: minAmount, max: maxAmount };

              return {
                isValid: isAmountValid(value),
                message: template($labels.InvoiceDetailsTab.payments.modal.amount_out_of_range)(limits),
              };
            },
          }"
          :value="amount"
          :type="INPUT_TYPES.number"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.amount}*`"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.amount_placeholder"
          name="amount"
          class="col-12 px-0 my-2"
          data-test-id="amount-input"
          @changevalue="({ detail }) => amount = detail"
        />

        <ui-text-area
          v-validate.input="{
            isRequired: true,
            isMinLength: {
              message: 'This field cannot be blank',
              length: 1,
            },
            isMaxLength: {
              message: `The number of characters allowed is ${maxTextAreaLength}`,
              length: maxTextAreaLength,
            },
          }"
          :value="description"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.comments_placeholder"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.comments}* (${textAreaCharsCount} characters left)`"
          name="description"
          class="d-block my-2 w-100"
          data-test-id="description-input"
          :maxlength="maxTextAreaLength"
          @changevalue="textAreaManager"
        />
      </ui-validate>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ui-button
        :disabled="!isAddAllowed"
        data-test-id="add-button"
        @clickbutton="onAddPayment"
      >
        {{ $labels.InvoiceDetailsTab.payments.actions.add }}
      </ui-button>
    </div>
  </MuiModal>
</template>

<style lang="scss" scoped>
  [name="firstRow"] {
    order: 1;
  }

  [name="amount"] {
    order: 2;
  }

  [name="description"] {
    order: 3;
  }
</style>
