<script>
import get from 'lodash/get';
import toInteger from 'lodash/toInteger';
import toNumber from 'lodash/toNumber';
import defaultTo from 'lodash/defaultTo';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  MuiAlgoliaSelect,
  MuiModal,
} from '@emobg/motion-ui/v1';

import { Validate } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { displayBookingSelector } from '@domains/Invoicing/Invoices/helpers/invoices.helpers';

import { NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN, POSITIVE_BUT_ZERO_PATTERN } from '@/utils';

import { CancelButton, SaveButton } from '@/components';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { MAX_TEXT_AREA_LENGTH } from '@domains/Invoicing/const/TEXT_AREA_LENGTH';

export default {
  name: 'ServiceLineModal',

  components: {
    MuiModal,
    CancelButton,
    SaveButton,
    MuiAlgoliaSelect,
  },

  directives: { Validate },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    serviceData: {
      type: Object,
      default: () => ({}),
    },
    isAddMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      isFormValid: false,
      isDataUpdated: false,
      bookingId: undefined,
      serviceId: undefined,
      units: undefined,
      amount: undefined,
      description: undefined,
      maxTextAreaLength: MAX_TEXT_AREA_LENGTH,
      textAreaCharsCount: MAX_TEXT_AREA_LENGTH,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),

    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'serviceLine.STATUS.LOADING'),
      isError: state => get(state, 'serviceLine.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'customerData', 'hasNotServiceLines', 'hasServiceLinesWithBooking']),

    invoiceId: ({ invoiceData }) => get(invoiceData, 'id'),
    preselectedBookingId: ({ invoiceData }) => get(invoiceData, 'preselectedBookingId'),

    customerFilter() {
      return get(this, 'customerData.userId')
        ? `user_fk:${get(this, 'customerData.userId')}`
        : `company_fk:${get(this, 'customerData.companyId')}`;
    },

    isBookingSelectorAllowed() {
      return this.isAddMode && !this.preselectedBookingId && (this.hasNotServiceLines || this.hasServiceLinesWithBooking);
    },

    isBookingRequired() {
      return this.isBookingSelectorAllowed && this.hasServiceLinesWithBooking;
    },

    isSaveAllowed() {
      return this.isFormValid && this.isDataUpdated;
    },
  },

  watch: {
    isModalOpen(isOpen) {
      this.isOpen = isOpen;

      this.clearData();

      if (isOpen && !this.isAddMode) {
        this.fillData();
      }
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.NOTIFICATION_TYPES = NOTIFICATION_TYPES;
    this.POSITIVE_BUT_ZERO_PATTERN = POSITIVE_BUT_ZERO_PATTERN;
    this.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN = NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN;
    this.displayBookingSelector = displayBookingSelector;
    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };

    this.textAreaCharsCount = this.maxTextAreaLength;

    this.$watch(vm => [vm.serviceId, vm.units, vm.amount, vm.description], ([serviceId, units, amount, description]) => {
      this.isDataUpdated = serviceId !== get(this, 'serviceData.serviceId')
        || toInteger(units) !== get(this, 'serviceData.units')
        || toNumber(amount) !== get(this, 'serviceData.amount.value') / 100
        || defaultTo(description, '') !== defaultTo(get(this, 'serviceData.description'), '');
    });

    this.currentOperatorId = this.activeOperatorId;
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['putInvoiceLine', 'postInvoiceLine']),

    isFormAllValid(value) {
      this.isFormValid = value.detail.isValid && value.detail.areAllValidated;
    },

    onSave() {
      if (this.isFormValid) {
        if (this.isAddMode) {
          this.onAddService();
        } else {
          this.onUpdateService();
        }
      }
    },

    async onUpdateService() {
      const payload = {
        invoiceId: this.invoiceId,
        bookingId: this.bookingId,
        serviceId: this.serviceId,
        price: this.amount,
        units: this.units,
        description: this.description,
      };

      await this.putInvoiceLine({
        invoiceLineId: get(this, 'serviceData.id'),
        payload,
      });

      this.$emit('on:close');

      if (!this.isError) {
        this.$notify({
          message: this.$labels.InvoiceDetailsTab.service_lines.notifications.edit_successfully,
          textAction: '',
        });
        this.$emit('on:change');
      }
    },

    async onAddService() {
      const payload = {
        invoiceId: this.invoiceId,
        bookingFk: this.preselectedBookingId ? this.preselectedBookingId : this.bookingId,
        serviceFk: this.serviceId,
        price: this.amount,
        units: this.units,
      };

      await this.postInvoiceLine(payload);

      this.$emit('on:close');

      if (!this.isError) {
        this.$notify({
          message: this.$labels.InvoiceDetailsTab.service_lines.notifications.add_successfully,
          textAction: '',
        });
        this.$emit('on:change');
      }
    },

    fillData() {
      this.bookingId = get(this, 'serviceData.bookingId');
      this.serviceId = get(this, 'serviceData.serviceId');
      this.units = get(this, 'serviceData.units');
      this.amount = get(this, 'serviceData.amount.value') / 100;
      this.description = get(this, 'serviceData.description');
    },

    clearData() {
      this.bookingId = undefined;
      this.serviceId = undefined;
      this.units = undefined;
      this.amount = undefined;
      this.description = undefined;
      this.textAreaCharsCount = this.maxTextAreaLength;
    },

    textAreaManager(text) {
      if (text.detail.length >= this.maxTextAreaLength) {
        this.textAreaCharsCount = 0;
        return;
      }

      this.description = text.detail;
      this.textAreaCharsCount = this.maxTextAreaLength - text.detail.length;

      this.validateAllInputs();
    },

    onChangeUnits(units) {
      this.units = units.detail;
      this.validateAllInputs();
    },

    onChangeAmount(amount) {
      this.amount = amount.detail;
      this.validateAllInputs();
    },

    validateAllInputs() {
      const validatableInputs = document.querySelectorAll('.ui-to-validate');

      Array.from(validatableInputs)
        .filter(({ value }) => value)
        .forEach(validatedElement => {
          validatedElement.dispatchEvent(new CustomEvent('validate', { bubbles: true, cancelable: true }));
        });
    },

    selectAlgolia() {
      this.validateAllInputs();
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="title"
    :header="headerObject"
    data-test-id="service_line-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <ui-validate
        class="d-flex flex-wrap"
        @status="isFormAllValid"
      >
        <ui-alert
          v-if="!isAddMode"
          :color="COLORS.warning"
          :icon="ICONS.alertFull"
          class="d-block mx-2 mb-4 order-1"
          data-test-id="notification"
        >
          {{ $labels.InvoiceDetailsTab.service_lines.modal.attention_text }}
        </ui-alert>

        <template v-if="isBookingSelectorAllowed">
          <MuiAlgoliaSelect
            v-if="isBookingRequired"
            v-model="bookingId"
            v-validate="{
              isRequired: true
            }"
            :index="ALGOLIA_INDEXES.csBookings"
            :filters="customerFilter"
            :title="booking => displayBookingSelector(booking)"
            :label="`${$labels.InvoiceDetailsTab.service_lines.modal.booking}*`"
            :placeholder="$labels.InvoiceDetailsTab.service_lines.modal.booking_placeholder"
            path-value="id"
            name="booking"
            class="col-12 mb-4 order-2"
            data-test-id="booking-select"
            @selected="selectAlgolia"
          />

          <MuiAlgoliaSelect
            v-else
            v-model="bookingId"
            :index="ALGOLIA_INDEXES.csBookings"
            :filters="customerFilter"
            :title="booking => displayBookingSelector(booking)"
            :label="`${$labels.InvoiceDetailsTab.service_lines.modal.booking}`"
            :placeholder="$labels.InvoiceDetailsTab.service_lines.modal.booking_placeholder"
            path-value="id"
            name="booking"
            class="col-12 mb-4 order-2"
            data-test-id="booking-select"
            @selected="selectAlgolia"
          />
        </template>

        <MuiAlgoliaSelect
          v-model="serviceId"
          v-validate.select="{
            isRequired: true
          }"
          :index="ALGOLIA_INDEXES.services"
          :filters="`cs_operator_fk: ${currentOperatorId}`"
          :title="service => `${service.code} ${service.internal_name}`"
          :label="`${$labels.InvoiceDetailsTab.service_lines.modal.service}*`"
          :placeholder="$labels.InvoiceDetailsTab.service_lines.modal.service_placeholder"
          path-value="id"
          name="service"
          class="col-12 mb-4 order-3"
          data-test-id="service-select"
          @selected="selectAlgolia"
        />

        <div class="col-6 order-4">
          <ui-text-input
            v-validate.input="{
              isRequired: true,
              isPattern: {
                pattern: POSITIVE_BUT_ZERO_PATTERN,
                message: `${$labels.InvoiceDetailsTab.service_lines.modal.units_error}`,
              },
            }"
            :value="units"
            :type="INPUT_TYPES.number"
            :label="`${$labels.InvoiceDetailsTab.service_lines.modal.units}*`"
            :placeholder="$labels.InvoiceDetailsTab.service_lines.modal.units_placeholder"
            name="units"
            class="w-100"
            data-test-id="units-input"
            @changevalue="onChangeUnits"
          />
        </div>

        <div class="col-6 order-5">
          <ui-text-input
            v-validate.input="{
              isRequired: true,
              isPattern: {
                pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
                message: `${$labels.InvoiceDetailsTab.service_lines.modal.price_error}`,
              },
            }"
            :value="amount"
            :type="INPUT_TYPES.number"
            :label="`${$labels.InvoiceDetailsTab.service_lines.modal.price}*`"
            :placeholder="$labels.InvoiceDetailsTab.service_lines.modal.price_placeholder"
            name="price"
            class="w-100"
            data-test-id="price-input"
            @changevalue="onChangeAmount"
          />
        </div>
        <ui-text-area
          v-if="!isAddMode"
          v-validate.input="{
            isRequired: true,
            isMinLength: {
              message: 'This field cannot be blank',
              length: 1,
            },
            isMaxLength: {
              message: `The number of characters allowed is ${maxTextAreaLength}`,
              length: maxTextAreaLength,
            },
          }"
          :value="description"
          :label="`${$labels.InvoiceDetailsTab.service_lines.modal.comments}* (${textAreaCharsCount} characters left)`"
          :placeholder="$labels.InvoiceDetailsTab.service_lines.modal.comments_placeholder"
          name="description"
          rows="4"
          class="d-block w-100 mt-4 mx-2 order-6"
          data-test-id="description-input"
          :maxlength="maxTextAreaLength"
          @changevalue="textAreaManager"
        />
      </ui-validate>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <SaveButton
        :disabled="!isSaveAllowed"
        data-test-id="save-button"
        @click="onSave"
      />
    </div>
  </MuiModal>
</template>
